import React from 'react';

export default function ScrollBottom() {
  return (
    <div className='scroll-bottom'>
      <p>Scroll</p>
      <span />
    </div>
  );
}
